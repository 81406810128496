import getConfig from 'next/config';

export type EnvironmentName = 'local' | 'development' | 'staging' | 'production';

type PublicConfigType = {
    API_URL: string;
    COMMERCE_URL: string;
    CUSTOMER_URL: string;
    ENVIRONMENT_NAME: EnvironmentName;
    VERBOSE_LOG: string;
};

export const runtimeConfig = () => {
    const { publicRuntimeConfig } = getConfig();
    return { ...(publicRuntimeConfig as PublicConfigType) };
};
